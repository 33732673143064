<template>
  <div class="image-upload">
    <div class="image-upload__thumb" v-if="type === 'circle'">
      <img
        :src="thumbnail"
        alt=""
      />
    </div>

    <vue-core-image-upload
      :class="classes"
      :crop="false"
      @imageuploaded="onImageUploaded"
      @imageuploading="onImageUploading"
      @errorhandle="onError"
      :data="requestData"
      :headers="headers"
      :max-file-size="5242880"
      :url="uploadUrl"
      :text="text"
    />

    <ul class="image-upload__errors" v-if="errors.length">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
import VueCoreImageUpload from 'vue-core-image-upload';
import config from '../config';
import csrfFromCookie from '../helpers/csrfFromCookie';

export default {
  inject: ['theme'],

  props: {
    userId: {
      type: Number,
    },

    petId: {
      type: Number,
    },

    milestoneId: {
      type: Number,
    },

    competitionId: {
      type: Number,
    },

    thumbnail: {
      type: String,
    },

    isAvatar: {
      type: Boolean,
      default: false,
    },

    isTemporary: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'circle',
    },

    text: {
      type: String,
      default: 'Upload een foto',
    },

    hasOverlay: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    'vue-core-image-upload': VueCoreImageUpload,
  },

  data() {
    return {
      uploadUrl: config.baseApiUrl + config.apiRoutes.imageUpload,
      maxFileSize: 5 * 1024 * 1024, // 5MB
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      errors: [],
    };
  },

  mounted() {
    this.$store.dispatch('fetchCsrf')
      .then(() => {
        this.headers['X-XSRF-TOKEN'] = csrfFromCookie('XSRF-TOKEN');
      });
  },

  computed: {
    requestData() {
      const requestData = {};

      if (this.userId) {
        requestData.user_id = this.userId;
      }

      if (this.petId) {
        requestData.pet_id = this.petId;
      }

      if (this.milestoneId) {
        requestData.milestone_id = this.milestoneId;
      }

      if (this.competitionId) {
        requestData.competition_id = this.competitionId;
      }

      if (this.isAvatar) {
        requestData.is_avatar = 1;
      }

      if (this.isTemporary) {
        requestData.is_temporary = 1;
      }

      requestData.has_overlay = this.hasOverlay ? 1 : 0;

      requestData.theme = this.theme.value;

      return requestData;
    },

    classes() {
      const classes = ['btn', 'btn--primary'];

      if (this.type === 'circle') {
        classes.push('image-upload__upload');
      } else if (this.type === 'button') {
        classes.push('image-upload__button');
      }

      return classes;
    },
  },

  methods: {
    onImageUploaded(res) {
      this.errors = [];

      if (typeof res.errors !== 'undefined' && Object.keys(res.errors).length) {
        // eslint-disable-next-line no-console
        console.log(res.errors);
        this.errors.push('Er ging iets mis bij het uploaden.');
        return;
      }

      // console.log(res);
      this.$emit('uploaded', res);
    },

    onImageUploading() {
      this.errors = [];
    },

    onError(err) {
      this.errors = [];

      if (err.indexOf('FILE IS TOO LARGE') >= 0) {
        this.errors.push('Het bestand is te groot (max. 5MB)');
      } else if (err.indexOf('FILE NUM OVERLOAD') >= 0) {
        this.errors.push('Je hebt teveel bestanden geüpload');
      } else if (err.indexOf('TYPE ERROR') >= 0) {
        this.errors.push('Het bestand is niet het juiste formaat (JPG, PNG)');
      } else {
        this.errors.push('Er ging iets mis bij het uploaden.');
      }
    },
  },
};
</script>

<style>
.image-upload {
  position: relative;
  width: 6.5rem;
}

.image-upload__thumb {
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  overflow: hidden;
}

.image-upload__thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.image-upload__upload {
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0;
  border: 2px solid #fff;
  color: transparent !important;
}

.image-upload__upload:before {
  content: '';
  width: 60%;
  height: 60%;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../assets/icon-camera.svg) no-repeat center center;
  background-size: 100% auto;
}

.image-upload__errors {
  position: absolute;
  top: calc(100% + .75rem);
  left: 50%;
  background-color: var(--purple);
  color: #fff;
  font-size: .875rem;
  width: 240px;
  padding: .75rem .875rem;
  border-radius: .5rem;
  text-align: center;
  transform: translateX(-50%);
}

.image-upload__errors:before {
  content: '';
  border: 6px solid var(--purple);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  position: absolute;
  bottom: 100%;
  left: calc(50% - 6px);
}
</style>
